<template>
  <div>
    <h3 ref="intro">
      Quadrilateral
    </h3>
    <p>
      Quadrilateral is a shape obtained by joining four non-collinear points located on the same plane.Thus, a quadrilateral is a polygon with four vertices, four sides and four interior angles.
    </p>
    <h3 ref="formula">
      Four types of Quadrilateral
    </h3>
    <p>
      There are six types of quadrilateral. These are &mdash;
    </p>
    <ul class="a">
      <li>
        <h5 ref="formula">
          Trapezium
        </h5>
      </li>
      <p> A trapezium is a quadrilateral in which one pair of opposite sides is parallel. </p>
      <li>
        <h5 ref="formula">
          Parallelogram
        </h5>
        <p>
          Parallelogram is a quadrilateral in which both pairs of opposite sides are parallel.
          The opposite sides are of equal length.
        </p>
      </li>
      <li>
        <h5 ref="formula">
          Rhombus
        </h5>
        <p>   Rhombus is a quadrilateral in which both pairs of opposite sides are parallel and all sides are of the same length.</p>
      </li>
      <li>
        <h5 ref="formula">
          Square
        </h5>
        <p> Square is a parallelogram with each interior angle being a right angle and all sides being equal. </p>
      </li>
      <li>
        <h5 ref="formula">
          Rectangle
        </h5>
        <p> Rectangle is a parallelogram with each interior angle being a right angle. </p>
      </li> 
    </ul>
    <h3 ref="pg">
      MagicGraph | Types of Quadrilateral
    </h3>
    <br>
    <h5> Getting Started </h5>
    <p>
      In this MagicGraph, you will learn about how to find the perimeter of a rectangular shape.
      Shown in the MagicGraph is a rectangular shape with its sides shown in grey.
      <br><br>
      Simply tap on a side to pick it. Once picked, the color of the side turns bright blue, and its length is added to the peripheral length.
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      You can tap on <i class="shuffler ma-1" /> icon  to shuffle and generate a new shape. You can tap on the
      <i class="eraser ma-1" /> icon to erase and start over.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Quads',
  created () {
    this.$store.commit('navigation/resetState');
    let title = 'Types of Quadrilaterals';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Quadrilaterals',
        //  titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively types of quadrilaterals.'}
                ]
        }
   },
}
</script>
